import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  langData = [
    {value: 1, value2: 'en', image: 'assets/imgs/003-uk.svg', name: 'English'},
    {value: 2, value2: 'ar', image: 'assets/imgs/003-saudi-arabia.svg', name: 'Arabic'},
    {value: 3, value2: 'th', image: 'assets/imgs/thailand.svg', name: 'Thai'},
    {value: 4, value2: 'sp', image: 'assets/imgs/001-spain.svg', name: 'Spanish'},
    {value: 5, value2: 'ma', image: 'assets/imgs/002-china.svg', name: 'Mandarin'},
    {value: 6, value2: 'ru', image: 'assets/imgs/001-russia.svg', name: 'Russian'},
    {value: 7, value2: 'po', image: 'assets/imgs/002-portugal.svg', name: 'Portuguese'},
    {value: 8, value2: 'fr', image: 'assets/imgs/002-portugal.svg', name: 'French'},
  ];

  translate2 = ['en', 'fr'];  

  langSelect

  constructor(public translate: TranslateService) { 

    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    // translate.use('fr');

  }

  	ngOnInit() {

				$(document).ready(function() {
				    var owl = $("#owl-demo");
				    owl.owlCarousel({
				        navigation : false,
				        pagination : false,
				        singleItem : true,
				        transitionStyle : "fade",
				        autoPlay : true,
				        //navigationText: [ '<i class="fa fa-angle-left fa-4x"></i>', '<i class="fa fa-angle-right fa-4x"></i>' ],
				    });
			    }); 

		$(document).ready(function () {
		  var Bannerheight = $('.banner-section').outerHeight();
			  $(window).scroll(function () {
			  if ($(this).scrollTop() > Bannerheight)
			  {
			    $('.header-sec').addClass('sticky');
			  } 
			  else {
			    $('.header-sec').removeClass('sticky');
			  }
			});
		});

		$(document).ready(function () {
		  if ($(window).width() <= 767) {
		  	$(window).scroll(function () {
			  if ($(this).scrollTop() > 1)
			  {
			    $('.header-sec').addClass('sticky');
			  } 
			  else {
			    $('.header-sec').removeClass('sticky');
			  }
			});
		  }
		});
  	}


  	_selectLanguage(data){
        console.log(data)
		this.translate.use(data);

  	}



}
