import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/services/website.service';
import { NgForm } from '@angular/forms';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  Form
  contactData

  constructor(private fb: FormBuilder,private websiteService:WebsiteService) { 
      this.contactData = {}
    this.Form = fb.group({
       'name': [null, Validators.compose([Validators.required])],
       'email': [null, Validators.compose([Validators.required])],
       'message' : [null, Validators.compose([Validators.required])],
    })

  }

  ngOnInit() {

		this.scrollToTop();

  }


  	scrollToTop() {
  	   setTimeout(() => {
  			var a = document.getElementsByTagName('h4')[0].scrollIntoView();
  		}, 1000);
  	}

  	
	DetailsOfContactUs(){
	  	console.log("bvfdsahhfdhjfdsa")
	  	this.websiteService.DetailsOfContactUsPage(this.contactData).subscribe(data=>{
	  		
	  		  console.log("KKKKKKKK" + data);
	          if(data.response == true){
	              console.log(data.result)

	              this.Form.reset();

	              alert(data.message)
	               // this.pageData = data.result.text
	          }else{
	            console.log("error : " + data.message)
	            alert(" Server Encountered with some error, Please try again")
	            // this.toastr.setRootViewContainerRef(this.vcr);
	            //   this.toastr.error('Sever encountered with error, try again!', 'Oops!');
	          }
				// this.contactData.name ="";
				// this.contactData.email="";
				// this.contactData.message="";

	  	},err=>{
	          console.log(err)
	          alert(" Server encountered with some error, Please try again")
	          // this.toastr.setRootViewContainerRef(this.vcr);
	             // this.toastr.error('Sever encountered with error, try again!', 'Oops!');
	    })

	}




}
