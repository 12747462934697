import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './landing/landing.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { FaqComponent } from './faq/faq.component'
import { AboutusComponent } from './aboutus/aboutus.component'
import { TermsComponent } from './terms/terms.component'
import {ContactUsComponent} from './contact-us/contact-us.component'
import { BlogComponent } from './blog/blog.component'

const routes: Routes = [
  { path: 'landing', component: LandingComponent },
  {path: 'contact-us', component:ContactUsComponent},
  { path: 'landing/:data', component: LandingComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'about-us', component: AboutusComponent },
  { path: 'terms-conditions', component: TermsComponent },
  {path:'blog',component:BlogComponent},
   {
        path: '',
        redirectTo: '/landing',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [ 
  RouterModule.forRoot(routes,{ useHash: true }),
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
