import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/services/website.service'
import { DomSanitizer } from '@angular/platform-browser';
declare var $;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  
  pageData

  constructor(private websiteService:WebsiteService, private domSanitizer:DomSanitizer) { }

  ngOnInit() {
  	this.scrollToTop();
    this._getDetailOfFAQpage();

          $(document.body).on('click', '.faq-content' ,function(){
            $('.faq-description').slideUp();
            if($(this).hasClass('last-clicked')){
                $(this).removeClass('last-clicked');
            }else{
                $(this).children('.faq-description').slideDown();
                $(this).addClass('last-clicked');
            }
          });

  }

  	scrollToTop() {
  	  setTimeout(() => {
  			var a = document.getElementsByTagName('h4')[0].scrollIntoView();
  		}, 1000);
  	}

    _getDetailOfFAQpage(){  
        this.websiteService._getDetailsOfFaqPage().subscribe(data=>{
          console.log("KKKKKKKK" + data);
          if(data.response == true){
              console.log(data.result)
              this.pageData = data.result
          }else{
            console.log("error : " + data.message)
            // this.toastr.setRootViewContainerRef(this.vcr);
            //   this.toastr.error('Sever encountered with error, try again!', 'Oops!');
          }
        },err=>{
          console.log(err)
          // this.toastr.setRootViewContainerRef(this.vcr);
             // this.toastr.error('Sever encountered with error, try again!', 'Oops!');
        })
    }


}
