import { Component, OnInit } from '@angular/core';
import { WebsiteService } from '../shared/services/website.service'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  sendData = {
    type : 'about',
    language_id : 1
  }
  pageData

  constructor(private websiteService:WebsiteService, private domSanitizer:DomSanitizer) { }

  ngOnInit() {
  	this.scrollToTop();
    this._getDetailOfContentManagement(this.sendData);
  }

  	scrollToTop() {
  	   setTimeout(() => {
  			var a = document.getElementsByTagName('h4')[0].scrollIntoView();
  		}, 1000);
  	}

    _getDetailOfContentManagement(data){  
        this.websiteService._getDetailsOfContentManagementPage(data).subscribe(data=>{
          console.log("KKKKKKKK" + data);
          if(data.response == true){
              console.log(data.result)
               this.pageData = data.result.text
          }else{
            console.log("error : " + data.message)
            // this.toastr.setRootViewContainerRef(this.vcr);
            //   this.toastr.error('Sever encountered with error, try again!', 'Oops!');
          }
        },err=>{
          console.log(err)
          // this.toastr.setRootViewContainerRef(this.vcr);
             // this.toastr.error('Sever encountered with error, try again!', 'Oops!');
        })
    }

    getSafeContent(data){
      return this.domSanitizer.bypassSecurityTrustHtml(data);
    }

}
