import { Injectable } from '@angular/core';
import { Http ,Response,Headers,RequestOptions,ResponseContentType} from '@angular/http';
// import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// import 'rxjs/add/operator/map';
// import {Observable} from 'rxjs/Rx';
// import { BehaviorSubject } from 'rxjs';
// import 'rxjs/Rx';
import {environment} from '../../../environments/environment.prod';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';

// @Injectable({
//   providedIn: 'root'
// })

  const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

@Injectable()
export class WebsiteService {

  constructor(private http: HttpClient) { }


  // HtmlContentApp(body): Observable<any> {
  //   const url = `${ENV.mainApi}HtmlContentApp`;
  //   return this.http.post<any>(url,body,httpOptions)
  //         .pipe(
  //         tap(heroes => this.log(`post   HtmlContentApp Test`)),
  //         catchError(this.handleError('post   HtmlContentApp Test', []))
        
  //   );
  // }

    _getDetailsOfContentManagementPage(body): Observable<any>{
        const url = environment.endPoint+"HtmlContentApp";
        return this.http.post<any>(url,body,httpOptions)
          .pipe(
          tap(heroes => this.log(`post   HtmlContentApp Test`)),
          catchError(this.handleError('post   HtmlContentApp Test', []))   
        );
    }

    // _getDetailsOfContentManagementPage(data): Observable<any>{
    //     let api = environment.endPoint+"HtmlContentApp";
    //     let body= data;
    //     return this.http.post(api,body)
    //     .map(response =>{
    //         return response.json();
    //     }).catch(error =>{
    //         return error;
    //     })
    // }

    // _getDetailsOfFaqPage(): Observable<any>{
    //     let api = environment.endPoint+"faqApp";
    //     return this.http.get(api)
    //     .map(response =>{
    //         return response.json();
    //     }).catch(error =>{
    //         return error;
    //     })
    // }

    _getDetailsOfFaqPage(): Observable<any>{
        let url = environment.endPoint+"faqApp";
         return this.http.get<any>(url,httpOptions)
              .pipe(
              tap(heroes => this.log(`get   faqApp Test`)),
              catchError(this.handleError('get   faqApp Test', []))
            
        );
    }


    // DetailsOfContactUsPage(data): Observable<any>{
    //     console.log(data)
    //     let api = environment.endPoint+"contactus";
    //     let body= data;

    //     return this.http.post(api,body)
    //     .map(response =>{
    //         return response.json();
    //     }).catch(error =>{
    //         return error;
    //     })
    // }


    DetailsOfContactUsPage(body): Observable<any>{
        let url = environment.endPoint+"contactus";
        return this.http.post<any>(url,body,httpOptions)
          .pipe(
          tap(heroes => this.log(`post   contactus Test`)),
          catchError(this.handleError('post   contactus Test', []))   
        );
    }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////
      /**
       * Handle Http operation that failed.
       * Let the app continue.
       * @param operation - name of the operation that failed
       * @param result - optional value to return as the observable result
       */
      private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
     
          // TODO: send the error to remote logging infrastructure
          console.error(error); // log to console instead
     
          // TODO: better job of transforming error for user consumption
          this.log(`${operation} failed: ${error.message}`);
     
          // Let the app keep running by returning an empty result.
          return of(result as T);
        };
      }
     
      /** Log a HeroService message with the MessageService */
      private log(message: string) {
        // this.messageService.add('HeroService: ' + message);
        console.log(message)
      }

}


