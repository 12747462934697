import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  
  scrollTill
  constructor(private route: ActivatedRoute, public router: Router) {
      
      if(this.route.params){      	
	      this.route.params.subscribe(res => {
	        console.log(res)
            this.scrollTill = res.data
            console.log(this.scrollTill)
            this.scrollTillClicked();
	      });
      }

  }

  ngOnInit() {
    // if(this.scrollTill != null && this.scrollTill != ''){
    // 	this.scrollTillClicked();
    // }
  }

  	scrollTillClicked(){
  		console.log("check scrool *****************************")
  		var a
  		if(this.scrollTill == 1){
  			// alert("hello!")
	  	    setTimeout(() => {
  	    		// a = document.getElementsByTagName('section')[1].scrollIntoView();
  	    		a = document.getElementById('Discover').scrollIntoView();
	        }, 1000);
  		}else if(this.scrollTill == 2){
  			console.log("llllllllllll")
	  	    setTimeout(() => {
  	    		// a = document.getElementsByTagName('section')[2].scrollIntoView();
  	    		a = document.getElementsByClassName('Match-section')[0].scrollIntoView();
	        }, 1000);
  		}else if(this.scrollTill == 3){
  			console.log("ffffffffffffff")
	  	    setTimeout(() => {
  	    		a = document.getElementsByTagName('section')[3].scrollIntoView();
	        }, 1000);
  		}else if(this.scrollTill == 4){
  			console.log("jjjjjjjjjjjjjj")
	  	    setTimeout(() => {
  	    		a = document.getElementsByTagName('section')[4].scrollIntoView();
	        }, 1000);
  		}
  	}

	// scroll(el) {
	//     el.scrollIntoView();
	// }

}
